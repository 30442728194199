import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import Loading from './../../Loading';
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import {
    Link,
} from "react-router-dom";

import NumberFormat from 'react-number-format';

// function Card(props) { return(
//     <div>
//     <h1>{props.tilte}</h1>
//     <p>{props.body}</p>
//     </div>
//     )}



class SetKeysTrade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            s: 'marketbase',
            d: 'irt',
            dd: 'irt',
            error: null,
            isLoaded: false,
            items: [],
            type: 'ارز',
            exchange: 'صرافی',
            marketbase: 'پایه بازار',
            bidorasks: 'نوع قیمت'

        };

    }

    ShowloginMessage(e, s) {
        swal(e, '', s);
    }

    getCookie(name) {
        // get cooki login
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }


    handleChangeBot(e) {
        e.preventDefault();

        this.setState({
            publickey: e.target.value
        })
    }
    handleChangeBot2(e) {
        e.preventDefault();

        this.setState({
            privatekey: e.target.value
        })
    }

    handleCloseBot(e) {

        this.setState({
            setShowBot: false
        })
    }

    handleShowBot(e) {

        this.setState({
            setShowBot: true
        })
    }

    handleChangeExchange(e) {
        e.preventDefault();
        this.setState({
            exchange: e.target.value
        })
    }

    SubmitHandler(d) {
        // ther button delete

        d.preventDefault();

        // if user not login so dont send request
        if (this.getCookie('__react_session__') == null) {
            this.ShowloginMessage('لطفا وارد حساب کاربری خود شوید', 'error');
        } else {
            if (this.state.exchange === 'صرافی') {
                this.ShowloginMessage('لطفا صرافی را انتخاب کنید', 'info');
            } else {
                const requestOptions = {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                    body: JSON.stringify({
                        'api_token': this.getCookie('__react_session__')['token'],
                        'publickey': this.state.publickey, 'privatekey': this.state.privatekey,
                        'exchange': this.state.exchange
                    })
                };

                fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/key/set", requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (result.status === 200) {
                                this.ShowloginMessage('با موفقیت اضافه شد', 'success');
                                this.setState({
                                    setShow: false
                                })
                            }
                            if (result.status === false) {
                                this.ShowloginMessage('انجام نشد', 'info');
                            }
                            if (result.status === 'coinnotfound') {
                                this.ShowloginMessage('بازاری را که انتخاب کردید وجود ندارد', 'error');
                            }
                        },
                        (error) => {
                            this.ShowloginMessage('به مشکل برخوردیم', 'error');
                        }

                    )
            }
        }
    }


    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }

    componentDidMount(props) {
        this.mounted = true;
    }



    render() {
        const { show, setShow, showBot, setShowBot, error, isLoaded, data } = this.state;


        return (

            <div>


                {/*  modal for set private and public key */}

                <Modal
                    show={setShowBot}
                    onHide={this.handleCloseBot.bind(this)}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>تنظیمات API</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form onSubmit={this.SubmitHandler.bind(this)}>
                            <br />

                            <p style={{ textAlign: 'center', marginBottom: '1rem' }} >صرافی مورد نظر</p>
                            <DropdownButton id="dropdown-item-button" title={(this.state.exchange)} style={{ textAlign: 'center', color: 'white', marginBottom: '1rem' }}>
                                <Dropdown.Item as="button" onClick={this.handleChangeExchange.bind(this)} value="binance">بایننس</Dropdown.Item>
                                <Dropdown.Item as="button" onClick={this.handleChangeExchange.bind(this)} value="exir">اکسیر</Dropdown.Item>
                            </DropdownButton>

                            <p style={{ textAlign: 'center', marginBottom: '1rem' }} >PUBLIC KEY</p>
                            <Form.Control type="text" onChange={this.handleChangeBot.bind(this)} required />
                            <br />
                            <p style={{ textAlign: 'center', marginBottom: '1rem', marginBottom: '1rem' }} >PRIVATE KEY</p>
                            <Form.Control type="text" style={{ marginBottom: '1rem' }} onChange={this.handleChangeBot2.bind(this)} required />
                            <Button variant="primary" type="submit" size="lg" block>
                                ثبت
  </Button>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseBot.bind(this)}>
                            بستن فرم
          </Button>
                    </Modal.Footer>
                </Modal>


                <Button variant="secondary" size='sm' style={{ marginRight: '0.5rem' }} onClick={this.handleShowBot.bind(this)}>
                    تنظیمات API
      </Button>
            </div>

        );
    }
}

//Date(item.time)


export default SetKeysTrade;
