import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

import Loading from './../../Loading';

import { Icon } from "coinmarketcap-cryptocurrency-icons";
import swal from 'sweetalert';


// function Card(props) { return(
//     <div>
//     <h1>{props.tilte}</h1>
//     <p>{props.body}</p>
//     </div>
//     )}



class ClientOrdersTrade extends Component {

  constructor(props) {
    super(props);
    this.state = {
      market: this.props.market,
      exchange: this.props.exchange,
    };
  }

  ShowloginMessage(e, s) {
    swal(e, '', s);
  }

  getCookie(name) {
    // get cooki login
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
  }


  componentWillUnmount() {
    // stop sending ajax when client in other page
    this.mounted = false;

  }

  componentDidMount(props) {
    this.mounted = true;

    setInterval(() => {
      if (this.mounted) {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({
            'api_token': this.getCookie('__react_session__')['token'],
            'symbol': this.state.market
          })
        };

        fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/" + this.state.exchange + '/balance', requestOptions)
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                data: result.data,
                formInput: ''
              });

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                work: true,
                error
              });
            }
          )
      }

    }, 6000);

  }



  render() {
    const { show, setShow, error, isLoaded, data, exchange } = this.state;
if (!isLoaded) {
      return <div></div>;
    } else {

      if (exchange == 'exir') {
        var table = <Table hover responsive>
          <thead>
            <tr>
              <th>عنوان</th>
              <th>خرید/فروش</th>
              <th>بازار/محدود</th>
              <th>قیمت</th>
              <th>مقدار</th>
              <th>ارز</th>
              <th>پر شده</th>
              <th>تاریخ</th>
            </tr>
          </thead>
          <tbody>

            {(data !== undefined) ? data.map(item => (

              <tr key={Math.random()}>
                <td>{item.title}</td>
                <td>{item.side}</td>
                <td>{item.type}</td>
                <td>{item.price}</td>
                <td>{item.size}</td>
                <td>{item.symbol}</td>
                <td>{item.filled}</td>
                <td>{item.created_at}</td>
              </tr>
            )) : <tr>هیچ سفارشی ثبت نشده</tr>}
          </tbody>
        </Table>;
      }
      if (exchange == 'binance') {
        var table = <Table hover responsive>
          <thead>
            <tr>
              <th>نماد</th>
              <th>شماره سفارش</th>
              <th>قیمت</th>
              <th>origQty</th>
              <th>executedQty</th>
              <th>cummulativeQuoteQty</th>
              <th>وضعیت</th>
              <th>بازار/محدود</th>
              <th>خرید/فروش</th>
              <th>بازار/محدود</th>

            </tr>
          </thead>
          <tbody>

            {(data !== undefined) ? data.map(item => (

              <tr key={Math.random()}>
                <td>{item.symbol}</td>
                <td>{item.orderId}</td>
                <td>{item.price}</td>
                <td>{item.origQty}</td>
                <td>{item.executedQty}</td>
                <td>{item.cummulativeQuoteQty}</td>
                <td>{item.status}</td>
                <td>{item.type}</td>
                <td>{item.side}</td>
              </tr>
            )) : <tr>هیچ سفارشی ثبت نشده</tr>}
          </tbody>
        </Table>;
      }
      return (

        <div style={{ width: '100%' }}>
          <br></br>
          <Col md={12} style={{ borderStyle: 'solid', borderWidth: '0.1px', borderColor: 'rgb(195, 237, 48)' }}>

            {table}

          </Col>
        </div>

      );
    }
  }
}

//Date(item.time)


export default ClientOrdersTrade;
