
import React, { Component } from 'react';

// import header panel

import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';
import swal from 'sweetalert';

import NumberFormat from 'react-number-format';
import { Icon } from "coinmarketcap-cryptocurrency-icons";

import Loading from './../../Loading';



class SellBuyTrade extends Component {


    constructor(props) {
        super(props);
        this.state = {

            data: '',
            exchange: this.props.exchange,
            market: this.props.market,
            marketbase: this.props.marketbase,
            type: this.props.type,
            price: this.props.price,
            error: null,
            balance : '',
            items: [],

        };

    }

    ShowloginMessage(e, s) {
        swal(e, '', s);
    }

    getCookie(name) {
        // get cooki login
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }

    SubmitHandlerSell(e) {

        e.preventDefault();
        let status = 0;
        console.log(this.state.marketRequest)
        if (this.state.marketRequest === undefined) {
            this.ShowloginMessage('لطفا برای خرید خود بازار یا محدود رو انتخاب کنید', 'info');
        }
        if (this.state.marketRequest === 'MarketBuy') {
            var requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'api_token': this.getCookie('__react_session__')['token'],
                    'symbol': this.state.market,
                    'side': 'buy',
                    'size': parseFloat(this.state.sizeBuy),
                    'type': 'market',
                    'price': '0',
                })
            };
        }
        if (this.state.marketRequest === 'MarketSell') {
            var requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'api_token': this.getCookie('__react_session__')['token'],
                    'symbol': this.state.market,
                    'side': 'sell',
                    'size': parseFloat(this.state.sizeSell),
                    'type': 'market',
                    'price': '0',
                })
            };
        }
        if (this.state.marketRequest === 'NotMarketBuy') {
            var requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'api_token': this.getCookie('__react_session__')['token'],
                    'symbol': this.state.market,
                    'side': 'buy',
                    'size': parseFloat(this.state.sizeBuy),
                    'type': 'limit',
                    'price': this.state.priceBuy,
                })
            };
        }
        if (this.state.marketRequest === 'NotMarketSell') {
            var requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'api_token': this.getCookie('__react_session__')['token'],
                    'symbol': this.state.market,
                    'side': 'sell',
                    'size': parseFloat(this.state.sizeSell),
                    'type': 'limit',
                    'price': this.state.priceSell,
                })
            };
        }



        fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/" + this.state.exchange + '/sell', requestOptions)
            .then(res => res.json())
            .then(
                (result) => {

                    // for exir exchange
                    if (result.status == 405) {
                        this.ShowloginMessage('لطفا API صرافی را در بالای همین صفحه وارد کنید', 'info');
                    }

                    if (result.message !== '' && result.message !== undefined) {
                        this.ShowloginMessage(result.message, 'info');
                    }
                    if (result.size !== undefined) {
                        this.ShowloginMessage('عملیات با موفقیت انجام شد', 'info');
                    }

                    //for binance exchange
                    if (result.status !== undefined && result.status !== 405) {
                        this.ShowloginMessage('عملیات با موفقیت انجام شد وضعیت سفارش شما' + result.status, 'info');
                    }


                    if (result.msg !== '' && result.msg !== undefined) {
                        this.ShowloginMessage(result.msg, 'info');
                    }

                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        work: true,
                        error
                    });
                }
            )
    }

    handleChangeMarketSell(e) {
        //   e.preventDefault();
        this.setState({
            marketRequest: 'MarketSell'
        })

    }

    handleChangeNotMarketSell(e) {
        //  e.preventDefault();
        this.setState({
            marketRequest: 'NotMarketSell'
        })

    }

    handleChangeMarketBuy(e) {
        //  e.preventDefault();
        this.setState({
            marketRequest: 'MarketBuy'
        })

    }

    handleChangeNotMarketBuy(e) {
        //   e.preventDefault();
        this.setState({
            marketRequest: 'NotMarketBuy'
        })

    }

    handleChangePriceSell(e) {
        //   e.preventDefault();
        this.setState({
            priceSell: e.target.value
        })
    }

    handleChangeSizeSell(e) {
        //   e.preventDefault();
        this.setState({
            sizeSell: e.target.value
        })

    }

    handleChangePriceBuy(e) {
        //   e.preventDefault();
        this.setState({
            priceBuy: e.target.value
        })

    }

    handleChangeSizeBuy(e) {
        //   e.preventDefault();
        this.setState({
            sizeBuy: e.target.value
        })
        console.log(e.target.value)
    }

    componentDidMount(props) {
        this.mounted = true;

        setInterval(() => {

            const requestOptionss = {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                    'api_token': this.getCookie('__react_session__')['token']
                    
                })
            };
    
            fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/" + this.state.exchange +"/allbalance", requestOptionss)
                .then(res => res.json())
                .then(
                    (result) => {
                        if(this.state.exchange == 'exir'){
                        this.setState({
                       balance : result[this.state.type + '_balance']
                    })}
                    if(this.state.exchange == 'binance'){

                        this.setState({
                            balance : 'undefiend'
                         })

                      {/*  Object.values(result).filter(
                            item => item.coin == this.state.type 
                          ).map(filtered => (
                           
                            this.setState({
                                balance : filtered
                             })
                            
                            ));   */}
                           

                    }
                   
                    },
                    (error) => {
                     
                    }
                )

        }, 8000);
       // console.log(this.state.balance)

    }


    render() {

        const { error, balance , isLoaded1, isLoaded2, orderbook, market, data, exchange, type, marketbase } = this.state;

        let sellNotMarket = "sellNotMarket";
        let sellMarket = "sellMarket";
        let BuyMarket = "BuyMarket";
        let BuyNotMarket = "buyNotMarket";

        if (exchange === 'nobitex') {

            return (
                <div className="orderlist" style={{
                    borderStyle: 'solid',
                    borderWidth: '0.1px',
                    borderColor: 'rgb(195, 237, 48)',
                    padding: '1rem',
                }}>
                    <br />
                    <p style={{ textAlign: 'center' }}>متاسفانه خرید و فروش در این صرافی غیر فعال است</p>
                </div>
            )
        }


        return (
            <div className="orderlist" style={{
                borderStyle: 'solid',
                borderWidth: '0.1px',
                borderColor: 'rgb(195, 237, 48)',
                padding: '1rem',
            }}>
                <p className="headsectionorder" style={{ backgroundColor: 'rgb(38, 166, 154)', color: 'white', marginTop: '0' }}>معامله {type} در پایه بازار {marketbase} در صرافی {exchange}</p>

                <Row>
                    {/*  start buy section */}

                    <Col md={6} style={{ borderLeftStyle: 'solid', marginTop: '0.5rem', textAlign: 'center' }}>

                        <ToggleButtonGroup type="radio" style={{ height: '35px', marginBottom: '0.5rem' }} name="options" defaultValue={1}>
                            <ToggleButton onClick={this.handleChangeMarketBuy.bind(this)} value="market">بازار</ToggleButton>
                            <ToggleButton onClick={this.handleChangeNotMarketBuy.bind(this)} value="price">محدود</ToggleButton>
                        </ToggleButtonGroup>

                        <Form onSubmit={this.SubmitHandlerSell.bind(this)}>

                            <Form.Control type="double" placeholder="مقدار" onChange={this.handleChangeSizeBuy.bind(this)} required style={{ marginBottom: '0.5rem' }} />

                            <Form.Control type="double" placeholder="قیمت" required onChange={this.handleChangePriceBuy.bind(this)} style={{ marginBottom: '0.5rem' }} />

                            <Button variant="primary" style={{ backgroundColor: '#26A69A', borderColor: 'white' }} type="submit" size="sm" block>خرید - موجودی ({balance})</Button>
                        </Form>
                    </Col>

                    {/*  start sell section */}

                    <Col md={6} style={{ marginTop: '0.5rem', textAlign: 'center' }}>

                        <ToggleButtonGroup type="radio" style={{ height: '35px', marginBottom: '0.5rem' }} name="options" defaultValue={1}>
                            <ToggleButton value="market" onClick={this.handleChangeMarketSell.bind(this)}>بازار</ToggleButton>
                            <ToggleButton value="price" onClick={this.handleChangeNotMarketSell.bind(this)}>محدود</ToggleButton>
                        </ToggleButtonGroup>

                        <Form onSubmit={this.SubmitHandlerSell.bind(this)}>

                            <Form.Control type="double" placeholder="مقدار" onChange={this.handleChangeSizeSell.bind(this)} required style={{ marginBottom: '0.5rem' }} />

                            <Form.Control type="double" placeholder="قیمت" required onChange={this.handleChangePriceSell.bind(this)} style={{ marginBottom: '0.5rem' }} />

                            <Button variant="primary" style={{ backgroundColor: '#EF5350', borderColor: 'white' }} type="submit" size="sm" block>فروش - موجودی ({balance})</Button>
                        </Form>
                    </Col>
                </Row>

            </div>

        );

    }

}


export default SellBuyTrade;
