
import React, { Component } from 'react';

// import header panel
import HeaderPanel from './Layouts/HeaderPanel';
import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import swal from 'sweetalert';

import { Icon, typeOf } from "coinmarketcap-cryptocurrency-icons";

import Loading from '../Loading';

import ClientOrdersTrade from './TraderC/ClientOrdersTrade'
// import trading view widget
import TradingViewWidget from 'react-tradingview-widget';
import SellBuyTrade from './TraderC/SellBuyTrade';
import OrderBookTrade from './TraderC/OrderBookTrade';
import SetKeysTrade from './TraderC/SetKeysTrade';

class Trade extends Component {


    constructor(props) {
        super(props);
        this.state = {

            data: '',
            exchange: 'exir',
            market: 'btc-irt',
            marketbase: 'irt',
            type: 'btc',
            method: 'get',
            orderbook: '',
            error: null,
            isLoaded: false,
            isLoaded1: false,
            isLoaded2: false,
            typesell: 'market',
            items: [],
            urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/exir?symbol=' + 'btc-irt',

        };

    }

    ShowloginMessage(e, s) {
        swal(e, '', s);
    }

    getCookie(name) {
        // get cooki login
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }

    BtnExir(e) {
        e.preventDefault();

        this.setState({
            exchange: 'exir',
            method: 'get',
            market: 'btc-irt',
            urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/exir?symbol=' + 'btc-irt',
            marketbase: 'irt',
            type: 'btc',
            isLoaded1: false,
            isLoaded2: false,
        })
    }

    BtnNobitex(e) {
        e.preventDefault();
        this.setState({
            exchange: 'nobitex',
            urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/nobitex?symbol=' + 'BTCIRT',
            market: 'BTCIRT',
            method: 'get',
            marketbase: 'irt',
            type: 'btc',
            isLoaded1: false,
            isLoaded2: false,
        })
    }

    BtnBinance(e) {
        e.preventDefault();
        this.setState({
            exchange: 'binance',
            urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/binance?symbol=' + 'BTCUSDT',
            market: 'BTCUSDT',
            method: 'get',
            marketbase: 'usdt',
            type: 'btc',
            isLoaded1: false,
            isLoaded2: false,
        })
    }

    handleChangeCoin(type, marketbase, d) {
        d.preventDefault();


        if (this.state.exchange === 'nobitex') {

            let symbol = type + marketbase;
            this.setState({

                isLoaded1: false,
                isLoaded2: false,
                market: type + marketbase,
                type: type,
                marketbase: marketbase,
                urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/nobitex?symbol=' + symbol,

            })
        }

        if (this.state.exchange === 'binance') {

            let symbol = type + marketbase;

            this.setState({

                isLoaded1: false,
                isLoaded2: false,
                market: type + marketbase,
                type: type,
                marketbase: marketbase,
                urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/binance?symbol=' + symbol.toUpperCase(),

            })
        }

        if (this.state.exchange === 'exir') {

            let symbol = type + '-' + marketbase;

            this.setState({

                isLoaded1: false,
                isLoaded2: false,
                market: symbol.toLowerCase(),
                type: type,
                marketbase: marketbase,
                urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/exir?symbol=' + symbol,

            })
        }



    }


    componentDidMount(props) {
        this.mounted = true;
        document.title = "خرید و فروش"

        setInterval(() => {
            if (this.mounted) {

                var requestOptions = {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                    //  body: JSON.stringify({ 's': this.state.s, 'd': this.state.d })
                };

                fetch(this.getCookie('__react_session__')['url']  + "/api/v1/coins", requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            this.setState({
                                isLoaded2: true,
                                isLoaded: true,
                                data: result.data,
                                formInput: ''
                            });

                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({

                                work: true,
                                error
                            });
                        }
                    )
            }
        }, 2000);

        //   setTimeout(() => {
        //       this.setState({
        //           isLoaded: true
        //       });
        //   }, 3000);

    }


    render() {

        const { error, isLoaded, isLoaded1, isLoaded2, orderbook, urlOrderBook, market, data, exchange, method, type, marketbase } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (isLoaded === false && isLoaded2 === false) {
            return <div> <HeaderPanel /><Loading /></div>;
        } else if (isLoaded === true && isLoaded2 === true) {

            return (
                <div>

                    <HeaderPanel />

                    <div className="paddingtop">
                        <SetKeysTrade />
                        <Row>

                            <Col md={3} style={{ borderStyle: 'solid', borderWidth: '0.1px', borderColor: 'rgb(195, 237, 48)' }}>
                                <OrderBookTrade
                                    exchange={exchange}
                                    market={market}
                                    marketbase={marketbase}
                                    type={type}
                                    urlOrderBook={urlOrderBook}
                                    method={method}
                                />
                            </Col>

                            {/*  finish bids and asks section */}

                            {/*  start tradingview section */}

                            <Col md={6}>

                                <div className="tradingview">
                                    <TradingViewWidget
                                        symbol={type.toUpperCase() + 'USDT'}
                                        locale="fr"
                                        autosize />
                                </div>
                                <br />
                                {/*  start sell and buy section */}

                                <SellBuyTrade exchange={exchange} marketbase={marketbase} type={type} market={market} />

                            </Col>
                            {/*  finish tradingview section */}

                            {/*  start tradingview section */}


                            <Col md={3} style={{ borderStyle: 'solid', borderWidth: '0.1px', borderColor: 'rgb(195, 237, 48)' }}>
                                <div className="orderlist" style={{ textAlign: 'center' }}>
                                    <p className="headsectionorder" >صرافی ها</p>
                                    <Table hover>
                                        <tbody className="orderlist" style={{ textAlign: 'center' }}>

                                            <br />
                                            <Button type="submit" style={{ marginBottom: '0.5rem', backgroundColor: '#EF5350', color: 'white', borderColor: 'white' }} onClick={this.BtnExir.bind(this)}>اکسیر</Button><br />
                                            <Button type="submit" style={{ marginBottom: '0.5rem', backgroundColor: '#EF5350', color: 'white', borderColor: 'white' }} onClick={this.BtnBinance.bind(this)}>بایننس</Button><br />
                                            <Button type="submit" style={{ marginBottom: '0.5rem', backgroundColor: '#EF5350', color: 'white', borderColor: 'white' }} onClick={this.BtnNobitex.bind(this)}>نوبیتکس</Button><br />

                                        </tbody>
                                    </Table>

                                </div>

                                {/*  markets section */}

                                <div className="orderlist" style={{ marginBottom: '1rem' }}>
                                    <p className="headsectionorder" style={{ backgroundColor: '#26A69A', color: 'white' }}>ارز های {exchange}</p>
                                    <Table hover style={{ paddingBottom: '0.5rem' }}>
                                        <thead>
                                            <tr style={{ display: 'block' }}>
                                                <th className="thtable" style={{ textAlign: 'right' }}>ارز</th>
                                                <th className="thtable" style={{ textAlign: 'right' }}>بازار</th>
                                                <th className="thtable" style={{ textAlign: 'right' }}>قیمت</th>
                                            </tr>
                                        </thead>
                                        <tbody className="orderlist" style={{ overflowY: 'auto' }}>
                                            {data.filter(
                                                item => item.exchange == exchange
                                            ).map(filtered => (
                                                <a href="#">  <tr key={Math.random()} onClick={this.handleChangeCoin.bind(this, filtered.type, filtered.marketbase)} style={{ display: 'block' }}>
                                                    <td className="tdtable" style={{ display: 'revert', padding: '0', textAlign: 'right' }}><Icon i={filtered.type} size={20} /> {filtered.type.toUpperCase()}</td>
                                                    <td className="tdtable" style={{ display: 'revert', padding: '0', textAlign: 'right' }}>{filtered.marketbase}</td>
                                                    <td className="tdtable" style={{ display: 'revert', padding: '0', textAlign: 'right' }}>{filtered.bids}</td>
                                                </tr></a>

                                            ))}
                                            <tr style={{ display: 'block' }}><td></td></tr>
                                            <tr style={{ display: 'block' }}><td></td></tr>
                                            <tr style={{ display: 'block' }}><td></td></tr>
                                            <tr style={{ display: 'block' }}><td></td></tr>
                                            <tr style={{ display: 'block' }}><td></td></tr>

                                        </tbody>
                                    </Table>

                                </div>

                            </Col>

                        </Row>
                       {(exchange !== 'nobitex') ?
                        <Row>
                            <ClientOrdersTrade exchange={exchange} market={market} />
                        </Row> : "" }
                    </div>

                </div >
            );
        } else {

            return <div> <HeaderPanel /><Loading /></div>;
        }

    }

}


export default Trade;
