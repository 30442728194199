import React, { Component } from 'react';

// function Card(props) { return(
//     <div>
//     <h1>{props.tilte}</h1>
//     <p>{props.body}</p>
//     </div>
//     )}



class Notfound extends Component {



  render() {
   

      return (

<h1>404</h1>
      );
}

}


export default Notfound;
