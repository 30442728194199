import React, { Component } from 'react';

import { Row, Table } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import { ButtonGroup } from 'react-bootstrap';


import Loading from '../Loading';
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import swal from 'sweetalert';

// import header panel
import HeaderPanel from './Layouts/HeaderPanel';

import NumberFormat from 'react-number-format';

// function Card(props) { return(
//     <div>
//     <h1>{props.tilte}</h1>
//     <p>{props.body}</p>
//     </div>
//     )}



class Coins extends Component {

  constructor(props) {
    super(props);
    this.state = {
      s: 'marketbase',
      d: 'irt',
      dd: 'irt',
      error: null,
      isLoaded: false,
      items: [],

    };

  }


  ShowloginMessage(e, s) {
    swal(e, '', s);
  }

  setshow(value) {
    return value
  }

  getCookie(name) {
    // get cooki login
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
  }

  Btnwatch(e, d) {
    // ther button add to watch list

    d.preventDefault();

    // if user not login so dont send request
    if (this.getCookie('__react_session__') == null) {
      this.ShowloginMessage('لطفا وارد حساب کاربری خود شوید', 'error');
    } else {

      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({ 'api_token': this.getCookie('__react_session__')['token'], 'coinid': e })
      };

      fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/addwatchlists", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {

            this.ShowloginMessage('با موفقیت اضافه شد', 'success');
            if (result.status === "rowexists") {
              this.ShowloginMessage('قبلا به لیست اضافه شده', 'info');
            }
          },
          (error) => {
            this.ShowloginMessage('به مشکل برخوردیم', 'error');
          }
        )
    }
  }

  BtnIrt(e) {
    e.preventDefault();
    this.setState({ s: 'marketbase', d: 'irt', dd: 'irt' })

  }

  BtnUsdt(e) {
    e.preventDefault();
    this.setState({ s: 'marketbase', d: 'usdt', dd: 'usdt' })

  }

  BtnExir(e) {
    e.preventDefault();
    this.setState({ s: 'exchange', d: 'exir' })
  }

  BtnBinance(e) {
    e.preventDefault();
    this.setState({ s: 'exchange', d: 'binance' })
  }

  BtnBtc(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'btc' })
  }

  BtnEth(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'eth' })
  }

  BtnXlm(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'xlm' })
  }

  BtnXrp(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'xrp' })
  }

  BtnUsdtt(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'usdt' })
  }

  BtnLtc(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'ltc' })
  }

  BtnTrx(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'trx' })
  }

  BtnEos(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'eos' })
  }

  BtnBch(e) {
    e.preventDefault();
    this.setState({ s: 'type', d: 'bch' })
  }

  BtnNobitex(e) {
    // e.preventDefault();
    this.setState({ s: 'exchange', d: 'nobitex' })

  }
  // inputHandler(e) {
  //   this.setState({ fromInput: e.target.value })
  // }


  componentWillUnmount() {
    // stop sending ajax when client in other page
    this.mounted = false;

  }

  componentDidMount(props) {
    this.mounted = true;
    document.title = "ارز های دیجیتال"
    //    console.log(this.props)

    setInterval(() => {
      if (this.mounted) {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({ 's': this.state.s, 'd': this.state.d })
        };

        fetch(this.getCookie('__react_session__')['url']  + "/api/v1/coins", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                data: result.data,
                formInput: ''
              });

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                work: true,
                error
              });
            }
          )
      }

    }, 2000);

  }



  render() {
    const { error, isLoaded, data } = this.state;


    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div><HeaderPanel /><Loading /></div>;
    } else {

      var tablecode;
      if (this.state.s != 'type') {
        // for exchange filter and usdt irt
        tablecode =
          data.filter(
            this.state.s != 'exchange' ?
              item => item.marketbase == this.state.d :
              item => item.exchange == this.state.d

          ).map(filtered => (

            <tr key={Math.random()}>
              <td><Icon i={filtered.type} size={30} />     {filtered.type.toUpperCase()}</td>
              <td> {filtered.exchange}</td>
              <td> {filtered.marketbase}</td>
              {/*  <td><NumberFormat value={parseFloat(filtered.bids).toString().slice(0, -1)} displayType={'text'} thousandSeparator={true} /></td>
            <td><NumberFormat value={parseFloat(filtered.asks).toString().slice(0, -1)} displayType={'text'} thousandSeparator={true} /></td>*/}
              <td><NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></td>
              <td><NumberFormat value={filtered.asks} displayType={'text'} thousandSeparator={true} /></td>
              <td>{filtered.update}{filtered.updated_at}</td>
              <td><Button className="btnp" type="button" onClick={this.Btnwatch.bind(this, filtered.id)}>تحت نظر</Button></td>
            </tr>
          ));
      } if (this.state.s == 'type') {
        tablecode =
          data.filter(

            item => item.type == this.state.d && item.marketbase == this.state.dd

          ).map(filtered => (

            <tr key={Math.random()}>
              <td><Icon i={filtered.type} size={30} />    {filtered.type.toUpperCase()}</td>
              <td> {filtered.exchange}</td>
              <td> {filtered.marketbase}</td>
              <td><NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></td>
              <td><NumberFormat value={filtered.asks} displayType={'text'} thousandSeparator={true} /></td>
              <td>{filtered.update}{filtered.updated_at}</td>
              <td><Button className="btnp" type="button" onClick={this.Btnwatch.bind(this, filtered.id)}>تحت نظر</Button></td>
            </tr>
          ));
      }

      return (
        <div>

          <HeaderPanel />

          <div className="paddingtop">

            <Button style={{ marginRight: '0.5rem' }} variant="secondary" type="button" onClick={this.BtnIrt.bind(this)}>تومان</Button>
            <Button variant="secondary" style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }} type="button" onClick={this.BtnUsdt.bind(this)}>تتر</Button>

            {/*       <Form onSubmit={this.formHandler.bind(this)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control type="text" onChange={this.inputHandler.bind(this)} placeholder="Enter email" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
  </Button>
          </Form>  */}

            <Card>
              <Card.Body>
                <div style={{ textAlign: 'center' }}>


                  <div style={{ float: 'center', textAlign: 'center' }} >
                    <Button style={{ marginRitgh: '0.5rem', marginLeft: '0.5rem' }} variant="info" type="button" onClick={this.BtnBinance.bind(this)}>بایننس</Button>
                    <Button style={{ marginLeft: '0.5rem' }} variant="info" type="button" onClick={this.BtnNobitex.bind(this)}>نوبیتکس</Button>
                    <Button variant="info" type="button" onClick={this.BtnExir.bind(this)}>اکسیر</Button>
                  </div>
                  <br />

                  <div style={{ float: 'center', display: 'inline-block' }}>
                    <Button style={{ marginRight: '0.5rem' }} variant="warning" type="button" onClick={this.BtnBtc.bind(this)}>بیت کوین</Button>
                    <Button variant="warning" style={{ marginRight: '0.5rem' }} type="button" onClick={this.BtnEth.bind(this)}>اتریوم</Button>
                    <Button style={{ marginRight: '0.5rem' }} variant="warning" type="button" onClick={this.BtnXrp.bind(this)}>ریپل</Button>
                    <Button variant="warning" style={{ marginRight: '0.5rem' }} type="button" onClick={this.BtnUsdtt.bind(this)}>تتر</Button>
                    <Button style={{ marginRight: '0.5rem' }} variant="warning" type="button" onClick={this.BtnLtc.bind(this)}>لایت کوین</Button>
                    <Button variant="warning" style={{ marginRight: '0.5rem' }} type="button" onClick={this.BtnTrx.bind(this)}>ترون</Button>
                    <Button style={{ marginRight: '0.5rem' }} variant="warning" type="button" onClick={this.BtnBch.bind(this)}>بیت کوین کش</Button>
                    <Button variant="warning" style={{ marginRight: '0.5rem' }} type="button" onClick={this.BtnEos.bind(this)}>ایاس</Button>
                    <Button variant="warning" style={{ marginRight: '0.5rem' }} type="button" onClick={this.BtnXlm.bind(this)}>استیلار</Button>
                  </div>

                </div>
                <br />


                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>ارز</th>
                      <th>صرافی</th>
                      <th>بازار</th>
                      <th>فروش</th>
                      <th>خرید</th>
                      <th>بروزرسانی</th>
                      <th>تحت نظر</th>
                    </tr>
                  </thead>
                  <tbody>



                    {tablecode}



                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>

        </div>


      );
    }
  }
}

//Date(item.time)


export default Coins;
