import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import Loading from '../Loading';
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import {
  Link,
} from "react-router-dom";


// import header panel
import HeaderPanel from './Layouts/HeaderPanel';

import NumberFormat from 'react-number-format';

// function Card(props) { return(
//     <div>
//     <h1>{props.tilte}</h1>
//     <p>{props.body}</p>
//     </div>
//     )}



class Alertlist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      s: 'marketbase',
      d: 'irt',
      dd: 'irt',
      error: null,
      isLoaded: false,
      items: [],
      type: 'ارز',
      exchange: 'صرافی',
      marketbase: 'پایه بازار',
      bidorasks: 'نوع قیمت'

    };

  }

  ShowloginMessage(e, s) {
    swal(e, '', s);
  }

  getCookie(name) {
    // get cooki login
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
  }

  handleChangeBidOrAsks(e) {
    e.preventDefault();
    this.setState({
      bidorasks: e.target.value
    })
  }

  handleChangePrice(e) {
    e.preventDefault();
    this.setState({
      price: e.target.value
    })
  }

  handleChangeType(e) {
    e.preventDefault();
    this.setState({
      type: e.target.value
    })
  }

  handleChangeExchange(e) {
    e.preventDefault();
    this.setState({
      exchange: e.target.value
    })
  }

  handleChangemarketbase(e) {
    e.preventDefault();

    this.setState({
      marketbase: e.target.value
    })
  }

  handleClose(e) {

    this.setState({
      setShow: false
    })
  }

  handleShow(e) {

    this.setState({
      setShow: true
    })
  }

  handleChangeBot(e) {
    e.preventDefault();

    this.setState({
      username: e.target.value
    })
  }

  handleCloseBot(e) {

    this.setState({
      setShowBot: false
    })
  }

  handleShowBot(e) {

    this.setState({
      setShowBot: true
    })
  }

  SubmitHandlerBot(d) {
    // ther button delete

    d.preventDefault();

    // if user not login so dont send request
    if (this.getCookie('__react_session__') == null) {
      this.ShowloginMessage('لطفا وارد حساب کاربری خود شوید', 'error');
    } else {

      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({
          'api_token': this.getCookie('__react_session__')['token'],
          'username': this.state.username
        })
      };

      fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/user/setteleuser", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status === 200) {
              this.ShowloginMessage('با موفقیت ویرایش شد', 'success');
              this.setState({
                setShow: false
              })
            }
            if (result.status === false) {
              this.ShowloginMessage('انجام نشد', 'info');
            }
            if (result.status === 201) {
              this.ShowloginMessage('این آی دی قبلا در سیستم ما استفاده شده', 'info');
            }
            if (result.status === 'coinnotfound') {
              this.ShowloginMessage('بازاری را که انتخاب کردید وجود ندارد', 'error');
            }
          },
          (error) => {
            this.ShowloginMessage('به مشکل برخوردیم', 'error');
          }
        )
    }
  }

  SubmitHandler(d) {
    // ther button delete

    d.preventDefault();

    // if user not login so dont send request
    if (this.getCookie('__react_session__') == null) {
      this.ShowloginMessage('لطفا وارد حساب کاربری خود شوید', 'error');
    } else {
      if (this.state.marketbase === 'پایه بازار') {
        this.ShowloginMessage('لطفا پایه بازار را انتخاب کنید', 'info');
      } else {
        if (this.state.exchange === 'صرافی') {
          this.ShowloginMessage('لطفا صرافی را انتخاب کنید', 'info');
        } else {
          if (this.state.type === 'ارز') {
            this.ShowloginMessage('لطفا ارز مورد نظر را انتخاب کنید', 'info');
          } else {
            if (this.state.bidorasks === 'نوع قیمت') {
              this.ShowloginMessage('لطفا نوع ارز را انتخاب کنید', 'info');
            } else {


              const requestOptions = {
                method: 'post',
                headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                body: JSON.stringify({
                  'api_token': this.getCookie('__react_session__')['token'],
                  'type': this.state.type, 'exchange': this.state.exchange, 'marketbase': this.state.marketbase,
                  'bidorasks': this.state.bidorasks, 'price': this.state.price
                })
              };

              fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/addalertlists", requestOptions)
                .then(res => res.json())
                .then(
                  (result) => {
                    if (result.status === 200) {
                      this.ShowloginMessage('با موفقیت اضافه شد', 'success');
                      this.setState({
                        setShow: false
                      })
                    }
                    if (result.status === false) {
                      this.ShowloginMessage('انجام نشد', 'info');
                    }
                    if (result.status === 'coinnotfound') {
                      this.ShowloginMessage('بازاری را که انتخاب کردید وجود ندارد', 'error');
                    }
                  },
                  (error) => {
                    this.ShowloginMessage('به مشکل برخوردیم', 'error');
                  }
                )
            }
          }
        }
      }
    }
  }

  Btnwatch(type, exchange, marketbase, bidorasks, price, d) {
    // ther button delete

    d.preventDefault();

    // if user not login so dont send request
    if (this.getCookie('__react_session__') == null) {
      this.ShowloginMessage('لطفا وارد حساب کاربری خود شوید', 'error');
    } else {

      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({
          'api_token': this.getCookie('__react_session__')['token'],
          'type': type, 'exchange': exchange, 'marketbase': marketbase,
          'bidorasks': bidorasks, 'price': price
        })
      };

      fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/deletealertlists", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status === 200) {
              this.ShowloginMessage('با موفقیت حذف شد', 'success');
            }
            if (result.status === false) {
              this.ShowloginMessage('انجام نشد', 'info');
            }
          },
          (error) => {
            this.ShowloginMessage('به مشکل برخوردیم', 'error');
          }
        )
    }
  }

  componentWillUnmount() {
    // stop sending ajax when client in other page
    this.mounted = false;

  }

  componentDidMount(props) {
    this.mounted = true;

    document.title = "هشدار قیمت"

    {/* update placeholder telegram username */ }

    const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      body: JSON.stringify({ 'api_token': this.getCookie('__react_session__')['token'] })
    };
    fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/user", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {

          if (result.status === 200) {

            this.setState({
              username: result.data.telegram_username
            })
          }
          if (result.status === false) {
            this.ShowloginMessage('انجام نشد', 'info');
          }
          if (result.status === 'coinnotfound') {
            this.ShowloginMessage('بازاری را که انتخاب کردید وجود ندارد', 'error');
          }
        },
        (error) => {
          this.ShowloginMessage('به مشکل برخوردیم', 'error');
        }
      )

    setInterval(() => {
      if (this.mounted) {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({ 'api_token': this.getCookie('__react_session__')['token'] })
        };

        fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/alertlists", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                data: result.data,
                formInput: ''
              });

            },
            (error) => {
              this.setState({
                isLoaded: true,
                work: true,
                error
              });
            }
          )
      }
    }, 2000);

  }



  render() {
    const { show, setShow, showBot, setShowBot, error, isLoaded, data } = this.state;


    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div> <HeaderPanel /><Loading /></div>;
    } else {


      return (

        <div>

          <HeaderPanel />

          <div className="paddingtop">

            {/*       <Form onSubmit={this.formHandler.bind(this)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control type="text" onChange={this.inputHandler.bind(this)} placeholder="Enter email" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
  </Button>
          </Form>  */}
            <br />



            {/*  modal for set new alert  */}

            <Modal
              show={setShow}
              onHide={this.handleClose.bind(this)}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>ثبت هشدار قیمت جدید</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Form onSubmit={this.SubmitHandler.bind(this)}>
                  <p style={{ textAlign: 'center' }}>پایه بازار را انتخاب کنید</p>
                  <DropdownButton id="dropdown-item-button" title={(this.state.marketbase === 'usdt') ? 'تتر' : 'تومان'} style={{ textAlign: 'center', color: 'white' }}>
                    <Dropdown.Item as="button" onClick={this.handleChangemarketbase.bind(this)} value="irt">تومان</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangemarketbase.bind(this)} value="usdt">تتر</Dropdown.Item>
                  </DropdownButton>
                  <br />

                  <p style={{ textAlign: 'center' }} >صرافی مورد نظر</p>
                  <DropdownButton id="dropdown-item-button" title={(this.state.exchange)} style={{ textAlign: 'center', color: 'white' }}>
                    <Dropdown.Item as="button" onClick={this.handleChangeExchange.bind(this)} value="binance">بایننس</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeExchange.bind(this)} value="exir">اکسیر</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeExchange.bind(this)} value="nobitex">نوبیتکس</Dropdown.Item>
                  </DropdownButton>

                  <br />
                  <p style={{ textAlign: 'center' }} >ارز مورد نظر را انتخاب کنید</p>
                  <DropdownButton id="dropdown-item-button" title={(this.state.type)} style={{ textAlign: 'center', color: 'white' }}>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="btc">بیت کوین</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="bch">بیت کوین کش</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="eth">اتریوم</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="usdt">تتر</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="ltc">لایت کوین</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="eos">ایاس</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="etc">اتریوم کلاسیک</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="trx">ترون</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="xlm">استیلار</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="bnb">بایننس کوین</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="xrp">ریپل</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="dai">دای</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="ada">ADA</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="link">LINK</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeType.bind(this)} value="xtz">XTZ</Dropdown.Item>
                  </DropdownButton>
                  <br />
                  <p style={{ textAlign: 'center' }} >نوع قیمت</p>
                  <DropdownButton id="dropdown-item-button" title={(this.state.bidorasks)} style={{ textAlign: 'center', color: 'white' }}>
                    <Dropdown.Item as="button" onClick={this.handleChangeBidOrAsks.bind(this)} value="bids">قیمت فروش</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={this.handleChangeBidOrAsks.bind(this)} value="asks">قیمت خرید</Dropdown.Item>
                  </DropdownButton>



                  <br />
                  <p style={{ textAlign: 'center' }} >قیمت هشدار را مشخص کنید</p>
                  <Form.Control type="number" placeholder="قیمت هشدار" onChange={this.handleChangePrice.bind(this)} required />
                  <br />
                  <Button variant="primary" type="submit" size="lg" block>
                    ثبت
  </Button>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                  بستن فرم
          </Button>
              </Modal.Footer>
            </Modal>



            {/*  modal for set username bot  */}
            <Alert variant='danger'>
              برای ارسال نوتیفیکشن هشدار لطفا وارد تنظیمات ربات شوید
  </Alert>
            <Modal
              show={setShowBot}
              onHide={this.handleCloseBot.bind(this)}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>تنظیمات بات تلگرام</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Alert variant='danger'>
                  لطفا اول آی دی تلگرام خود را وارد و ثبت کنید و در مرحله بعد ربات تلگرام برییج رو استارت کنید
  </Alert>
 
                <Form onSubmit={this.SubmitHandlerBot.bind(this)}>

                  <p style={{ textAlign: 'center' }} >لطفا آی دی تلگرام خود را وارد کنید</p>
                  <Form.Control type="text" onChange={this.handleChangeBot.bind(this)} placeholder={this.state.username} required />
                  <br />
                  <Button variant="primary" type="submit" size="lg" block>
                    ثبت
  </Button>
                </Form>
                <br  />
                <p style={{ textAlign: 'center' }} >بعد از ثبت وارد ربات ما شوید</p>
                <a  target="_blank" style={{ textAlign: 'center' }} href="https://t.me/tradernotiff_bot">tradernotiff_bot@</a>
                <Alert variant='info'>
                 اگر مراحل رو درست انجام داده باشید پیام شما به بات ما متصل شدید در تلگرام برای شما ارسال میشه
  </Alert>
             
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseBot.bind(this)}>
                  بستن فرم
          </Button>
              </Modal.Footer>
            </Modal>



            <Card>
              <Card.Body>
                <h3 style={{ textAlign: 'center' }}>ارز هایی که دارای هشدار قیمت هستند</h3>
                <br />

                <Button variant="secondary" onClick={this.handleShow.bind(this)}>
                  اضافه کردن هشدار جدید
      </Button>

                <Button variant="secondary" style={{marginRight:'0.5rem'}} onClick={this.handleShowBot.bind(this)}>
                  تنظیمات ربات
      </Button>



                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>ارز</th>
                      <th>صرافی</th>
                      <th>بازار</th>
                      <th>قیمت</th>
                      <th>خرید یا فروش</th>
                      <th>حذف از لیست</th>
                    </tr>
                  </thead>
                  <tbody>



                    {(data !== "") ? data.map(item => (

                      <tr key={Math.random()}>
                        <td><Icon i={item.type} size={30} />    {item.type.toUpperCase()}</td>
                        <td> {item.exchange}</td>
                        <td> {item.marketbase}</td>
                        <td><NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} /></td>
                        <td>{(item.bidorasks === 'asks') ? 'قیمت خرید' : 'قیمت فروش'}</td>
                        <td><Button variant="primary" type="button" onClick={this.Btnwatch.bind(this, item.type, item.exchange, item.marketbase, item.bidorasks, item.price)}>حذف</Button></td>
                      </tr>
                    )) : <tr>هیچ هشداری وجود ندارد</tr>}



                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div></div>



      );
    }
  }
}

//Date(item.time)


export default Alertlist;
