
import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import NumberFormat from 'react-number-format';
import { Icon } from "coinmarketcap-cryptocurrency-icons";


import Loading from '../Loading';

class Boxcoins extends Component {


    constructor(props) {
        super(props);
        this.state = {
            s: 'marketbase',
            d: 'irt',
            dd: 'irt',
            error: null,
            isLoaded: false,
            items: [],

        };

    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }
    
    getCookie(name) {
        // get cooki login
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
      }

    componentDidMount(props) {
        this.mounted = true;
        //    console.log(this.props)

        setInterval(() => {
            if (this.mounted) {
                const requestOptions = {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                    body: JSON.stringify({ 's': this.state.s, 'd': this.state.d })
                };

                fetch(this.getCookie('__react_session__')['url']  + "/api/v1/coins", requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            this.setState({
                                isLoaded: true,
                                data: result.data,
                                formInput: ''
                            });

                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                work: true,
                                error
                            });
                        }
                    )
            }

        }, 2000);

    }


    render() {

        const { error, isLoaded, data } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div></div>;
        } else {

            return (
                <div>
                    <Row>
                        {/* show bitcoin data in exchanges*/}

                        <Col sm className="reportbox" style={{ borderColor: '#C3ED30', textAlign: 'center' }}>

                            {data.filter(
                                item => item.id == '1'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <Icon i={filtered.type} size={50} />
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '25'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '40'
                            ).map(filtered => (
                                <div key={Math.random()}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                        </Col>

                        {/* show eth data in exchanges*/}

                        <Col sm className="reportbox" style={{ borderColor: '#C3ED30', textAlign: 'center' }}>

                            {data.filter(
                                item => item.id == '2'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <Icon i={filtered.type} size={50} />
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '32'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '41'
                            ).map(filtered => (
                                <div key={Math.random()}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                        </Col>

                        {/* show usdt data in exchanges*/}

                        <Col sm className="reportbox" style={{ borderColor: '#C3ED30', textAlign: 'center' }}>

                            {data.filter(
                                item => item.id == '3'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <Icon i={filtered.type} size={50} />
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '35'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                            <div key={Math.random()}>
                                <p style={{ textAlign: 'center', marginBottom: 'auto' }}>binance</p>
                                <p style={{ textAlign: 'center', marginBottom: 'auto' }}>---</p>
                            </div>


                        </Col>

                        {/* show ltc data in exchanges*/}

                        <Col sm className="reportbox" style={{ borderColor: '#C3ED30', textAlign: 'center' }}>

                            {data.filter(
                                item => item.id == '8'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <Icon i={filtered.type} size={50} />
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '26'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                            {data.filter(
                                item => item.id == '46'
                            ).map(filtered => (
                                <div key={Math.random()}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                        </Col>

                        {/* show xlm data in exchanges*/}

                        <Col sm className="reportbox" style={{ borderColor: '#C3ED30', textAlign: 'center' }}>

                            {data.filter(
                                item => item.id == '9'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <Icon i={filtered.type} size={50} />
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '28'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                            {data.filter(
                                item => item.id == '47'
                            ).map(filtered => (
                                <div key={Math.random()}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                        </Col>

                        {/* show XRP data in exchanges*/}

                        <Col sm className="reportbox" style={{ borderColor: '#C3ED30', textAlign: 'center' }}>

                            {data.filter(
                                item => item.id == '11'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <Icon i={filtered.type} size={50} />
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}

                            {data.filter(
                                item => item.id == '31'
                            ).map(filtered => (
                                <div key={Math.random()} style={{ textAlign: 'center', borderBottom: '1px solid rgb(195, 237, 48)' }}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                            {data.filter(
                                item => item.id == '49'
                            ).map(filtered => (
                                <div key={Math.random()}>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>{filtered.exchange}</p>
                                    <p style={{ textAlign: 'center', marginBottom: 'auto' }}>  {filtered.marketbase}  <NumberFormat value={filtered.bids} displayType={'text'} thousandSeparator={true} /></p>
                                </div>
                            ))}


                        </Col>
                    </Row>
                </div>

            );

        }
    }

}


export default Boxcoins;
