
import React, { Component } from 'react';

// import header index
import HeaderIndex from './Layouts/HeardeIndex';

//import img

import indeximage from './img/index.png';
import indexmobile from './img/indexmobile.png';
import linesimage from './img/lines.png';
import lines2image from './img/lines2.png';

import Boxcoins from './Panel/Boxcoins';
//import css

import './Css/index.css';

// import react router
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { Container } from 'react-bootstrap';
import { Card, CardDeck } from 'react-bootstrap';


class Index extends Component {



    render() {


        return (

            <div>

                <HeaderIndex />


                <div className="indeximage">
                    <img src={indeximage} className="center" style={{ width: '100%' }} />
                </div>
                <div className="indexmobile">
                    <img src={indexmobile} className="center" style={{ width: '100%' }} />
                </div>








                <Container className="pt-5">
                    <div className="mobilehead">
                        <div className="coloredbox center">
                            <h3 className="center">چه کمکی از دست ما بر میاد؟</h3>
                        </div>
                    </div>

                    <h5 className="center" style={{ lineHeight: '1.6' }}><br /><br /><br />
                        ما برای شما امکانی را فراهم کردیم تا بتونید در لحظه و در یک پنل با ده ها صرافی<br />
                خرید و فروش انجام بدید ، موجودی حسابتون رو چک کنید و<br />
                سفارشاتتون رو مدیریت کنید ، شما باید در صرافی مورد نظر حساب داشته باشید<br />
                و صرافی دارای خدمات api باشه.<br />

در یک پنل با ده ها صرافی کار بکنید!!</h5><br /><br />
                </Container>




                <div className="mr-auto" style={{ position: 'relative' }}>


                    <img src={linesimage} className="linesimage" />
                    <div className="mobilehead">
                        <div className="coloredinbox center textonimage" style={{ position: 'absolute' }}>
                            <h3 className="center">آیا ما قابل اعتماد هستیم؟</h3>
                        </div>
                    </div>

                    <div>
                        <h4 className="center textonimage2" style={{
                            position: 'absolute',
                            marginTop: '10rem',
                            backgroundColor: '#ffffffed',
                            Padding: '2rem',
                            height: 'max-content',
                            padding: '2rem',
                            lineHeight: '1.6',
                        }}>
                            سیستم ما فقط قادر به مشاهده و انجام عملیات ترید<br />
                        در حساب شماست و ما دسترسی به خروج ارز از حساب شما نداریم<br />
                        </h4>
                    </div>

                </div>





                <div className="mobilehead">
                    <div className="coloredbox center" style={{ marginTop: '-5rem' }}>
                        <h3 className="center">الان بیت کوین در صرافی ها چنده ؟</h3>
                    </div>
                </div>


                <Container style={{ paddingtop: '6rem' }}>
                <br />
                    <Boxcoins />
                </Container>
                <br />





                <div className="mr-auto" style={{ position: 'relative' }}>


                    <img src={lines2image} className="linesimage" />

                    <div className="mobilehead">
                        <div className="coloredinbox center textonimage3" style={{ position: 'absolute' }}>
                            <h3 className="center">صرافی هایی که ما پشتیبانی میکنیم</h3>
                        </div>
                    </div>

                    <div>
                        <h4 className="center textonimage2" style={{
                            position: 'absolute',
                            marginTop: '10rem',
                            backgroundColor: '#ffffffed',
                            Padding: '2rem',
                            height: 'max-content',
                            padding: '2rem',
                            lineHeight: '1.6',
                        }}>
                            سیستم ما فقط قادر به مشاهده و انجام عملیات ترید<br />
    در حساب شماست و ما دسترسی به خروج ارز از حساب شما نداریم<br />
                        </h4>
                    </div>

                </div>









                <div className="mobilehead">
                    <div className="coloredbox center" style={{ marginTop: '-5rem' }}>
                        <h3 className="center">امکانات ما</h3>
                    </div>
                </div>


                <Container>
                    <h5 className="center" style={{ lineHeight: '1.6' }}><br /><br /><br />
                حتی اگه مایل نیستی با قسمت خرید و فروش سایت ما کار بکنی
ما قسمتی داریم که قیمت تمام ارز ها در صرافی های ایرانی رو بهت نشون میده
و حتی میتونی ارز هارو تحت نظر بگیری و براشون هشدار تنظیم بکنی تا وقتی به
قیمت مورد نظر رسید پیامش به تلگرامت ارسال بشه</h5><br /><br />

                </Container>
                <br /><br />
                <div className="mobilehead">
                    <div className="coloredinbox center textonimage3">
                        <Link as={Link} to="/register" ><h3 className="center">منتظر چی هستی ؟ بزن بریم</h3></Link>
                    </div>
                </div>
                <br /><br /><br /><br />

                <div className="footer">
                    <Container>
                        <br /><br />
                        <h5 style={{ textAlign: 'center' }}>ما اهل شهر پل ها هستیم
                        به هر طرفی پل ساختیم تا
                        با همه داد و ستد داشته باشیم
                        ماها عاشق ارز دیجیتال هستیم
                        سازنده من کوشان هست
                        </h5>
                        <br /><br />
                    </Container>

                </div>


            </div>

        )
    }
}

export default Index;
