import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';

import Loading from '../Loading';
import { Icon } from "coinmarketcap-cryptocurrency-icons";
import swal from 'sweetalert';

// import header panel
import HeaderPanel from './Layouts/HeaderPanel';

import NumberFormat from 'react-number-format';

// function Card(props) { return(
//     <div>
//     <h1>{props.tilte}</h1>
//     <p>{props.body}</p>
//     </div>
//     )}



class Watchlist extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: 'ارز',
      exchange: 'صرافی',
      marketbase: 'پایه بازار',
      bidorasks: 'نوع قیمت'
    };
  }

  ShowloginMessage(e, s) {
    swal(e, '', s);
  }

  getCookie(name) {
    // get cooki login
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
  }

  Btnwatch(e, d) {
    // ther button add to watch list

    d.preventDefault();

    // if user not login so dont send request
    if (this.getCookie('__react_session__') == null) {
      this.ShowloginMessage('لطفا وارد حساب کاربری خود شوید', 'error');
    } else {

      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({ 'api_token': this.getCookie('__react_session__')['token'], 'coinid': e })
      };

      fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/deletewatchlists", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status === 200) {
              this.ShowloginMessage('با موفقیت حذف شد', 'success');
            }
            if (result.status === false) {
              this.ShowloginMessage('انجام نشد', 'info');
            }
          },
          (error) => {
            this.ShowloginMessage('به مشکل برخوردیم', 'error');
          }
        )
    }
  }


  componentWillUnmount() {
    // stop sending ajax when client in other page
    this.mounted = false;

  }

  componentDidMount(props) {
    this.mounted = true;

    document.title = "لیست ارز های تحت نظر"
    //    console.log(this.props)

    setInterval(() => {
      if (this.mounted) {
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
          body: JSON.stringify({ 'api_token': this.getCookie('__react_session__')['token'] })
        };

        fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/watchlists", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {

              this.setState({
                isLoaded: true,
                data: result.data,
                formInput: ''
              });

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded: true,
                work: true,
                error
              });
            }
          )
      }

    }, 2000);

  }



  render() {
    const { show, setShow, error, isLoaded, data } = this.state;


    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div><Loading /></div>;
    } else {


      return (

      <div>

            {/*       <Form onSubmit={this.formHandler.bind(this)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Control type="text" onChange={this.inputHandler.bind(this)} placeholder="Enter email" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
  </Button>
          </Form>  */}
            <br />
            <Card>
              <Card.Body>
                <h5 style={{ textAlign: 'right' }}>ارز های تحت نظر شما</h5>
                <br />
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>ارز</th>
                      <th>صرافی</th>
                      <th>بازار</th>
                      <th>فروش</th>
                      <th>خرید</th>
                      <th>حذف از لیست</th>
                    </tr>
                  </thead>
                  <tbody>



                    {(data !== "") ? data.map(item => (

                      <tr key={Math.random()}>
                        <td><Icon i={item.type} size={30} />    {item.type.toUpperCase()}</td>
                        <td> {item.exchange}</td>
                        <td> {item.marketbase}</td>
                        <td><NumberFormat value={item.bids} displayType={'text'} thousandSeparator={true} /></td>
                        <td><NumberFormat value={item.asks} displayType={'text'} thousandSeparator={true} /></td>
                        <td><Button className="btnp" type="button" onClick={this.Btnwatch.bind(this, item.id)}>حذف</Button></td>
                      </tr>
                    )) : <tr>هیچ ارزی تحت نظر شما نیست</tr>}



                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            </div>

      );
    }
  }
}

//Date(item.time)


export default Watchlist;
