
import React, { Component } from 'react';

import { Table } from 'react-bootstrap';
import swal from 'sweetalert';

import NumberFormat from 'react-number-format';

import Loading from './../../Loading';


class OrderBookTrade extends Component {


    constructor(props) {
        super(props);
        this.state = {

            data: '',
            exchange: this.props.exchange,
            market: this.props.market,
            marketbase: this.props.marketbase,
            type: this.props.type,
            method: this.props.method,
            orderbook: '',
            error: null,
            isLoaded1: false,
            items: [],
            urlOrderBook: this.props.urlOrderBook,

        };

        console.log(this.props.exchange)
        console.log(this.props.market)
        console.log(this.props.marketbase)
        console.log(this.props.type)

    }

    ShowloginMessage(e, s) {
        swal(e, '', s);
    }

    getCookie(name) {
        // get cooki login
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }


    componentDidMount(props) {
        this.mounted = true;
        document.title = "خرید و فروش"

        setInterval(() => {
            if (this.mounted) {

                if (this.state.method === 'get') {
                    var requestOptions = {
                        method: this.state.method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            "Access-Control-Allow-Headers": '*'
                        },
                        // body: JSON.stringify({ 'symbol': this.state.market })
                    };
                } else {
                    var requestOptions = {
                        method: this.state.method,
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            "Access-Control-Allow-Headers": '*'
                        },
                        body: JSON.stringify({ 'symbol': this.state.market })
                    };
                }

                fetch(this.state.urlOrderBook, requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {

                            this.setState({

                                isLoaded1: true,
                                orderbook: result,
                                formInput: ''
                            });


                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {

                            // if vpn client is off so show this msg
                            if (this.state.exchange === 'binance') {
                                this.setState({
                                    isLoaded1: false,
                                    market: 'btc-irt',
                                    type: 'btc',
                                    exchange: 'exir',
                                    urlOrderBook: this.getCookie('__react_session__')['url'] + '/api/v1/orderbook/exir?symbol=' + 'btc-irt',
                                })

                                this.ShowloginMessage('لطفا با فیلتر شکن وارد این صرافی شوید', 'info');

                            }
                        }
                    )


            }

        }, 2000);

        //   setTimeout(() => {
        //       this.setState({
        //           isLoaded: true
        //       });
        //   }, 3000);

    }


    render() {

        const { error, isLoaded1, orderbook, market, data, exchange, type, marketbase } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (isLoaded1 === false) {
            return <div><Loading /></div>;
        } else if (isLoaded1 === true) {


            if (exchange === 'nobitex' || exchange === 'binance') {

                if (orderbook['bids'] == undefined) {
                    return <div><Loading /></div>;
                }

                var bidstable =
                    (orderbook['bids']).map(item => (
                        <tr key={Math.random()} style={{ display: 'block' }}>
                            <td className="tdtable" ><NumberFormat style={{ color: '#EF5350' }} value={item[0]} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="tdtable"><NumberFormat value={item[1]} stye={{ marginRight: '0.5rem' }} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                    ));

                var askstable =
                    (orderbook['asks']).map(item => (
                        <tr key={Math.random()} style={{ display: 'block' }}>
                            <td className="tdtable" ><NumberFormat style={{ color: '#26A69A' }} value={item[0]} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="tdtable"><NumberFormat value={item[1]} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                    ));
            } if (exchange === 'exir') {


                if (orderbook[market] == undefined) {
                    return <div><Loading /></div>;
                }

                {/* change bids and asks in exir exchange */ }
                var exirbids = orderbook[market]['asks']
                var exirasks = orderbook[market]['bids']

                var bidstable =
                    exirbids.map(item => (
                        <tr key={Math.random()} style={{ display: 'block' }}>
                            <td className="tdtable" ><NumberFormat style={{ color: '#EF5350' }} value={item[0]} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="tdtable"><NumberFormat value={item[1]} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                    ));

                var askstable =
                    exirasks.map(item => (
                        <tr key={Math.random()} style={{ display: 'block' }}>
                            <td className="tdtable" ><NumberFormat style={{ color: '#26A69A' }} value={item[0]} displayType={'text'} thousandSeparator={true} /></td>
                            <td className="tdtable"><NumberFormat value={item[1]} displayType={'text'} thousandSeparator={true} /></td>
                        </tr>
                    ));
            }



            return (
                <div>

            
                            {/*  bids section */}
                            
                                <div className="orderlist">
                                    <p className="headsectionorder" style={{ backgroundColor: '#EF5350', color: 'white' }}>فروشندگان</p>
                                    <Table hover>
                                        <thead>
                                            <tr style={{ display: 'block' }}>
                                                <th className="thtable">قیمت</th>
                                                <th className="thtable">مقدار</th>
                                            </tr>
                                        </thead>
                                        <tbody className="orderlist">

                                            {bidstable}

                                        </tbody>
                                    </Table>

                                </div>
                                {/*  asks  section */}
                                <div className="orderlist">
                                    <p className="headsectionorder" style={{ backgroundColor: '#26A69A', color: 'white' }}>خریداران</p>
                                    <Table hover>
                                        <tbody className="orderlist">

                                            {askstable}

                                        </tbody>
                                    </Table>

                                </div>
                          

                            {/*  finish bids and asks section */}

                      
                    </div>
            );
        } else {

            return <div><Loading /></div>;
        }

    }

}


export default OrderBookTrade;
