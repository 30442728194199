import React, { Component } from 'react';

import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { NavDropdown } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import {
  Link,
} from "react-router-dom";


class HeaderPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: props.token,
      data: ''
    };
    this.mounted = true;
  }


  getCookie(name) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
  }


  CheckLogin() {
    if (this.getCookie('__react_session__') != null) {
      return true
    } else {
      return false
    }
  }

  componentDidMount() {
    const requestOptions = {
      method: 'post',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      body: JSON.stringify({ 'api_token': this.getCookie('__react_session__')['token'] })
    };

    fetch(this.getCookie('__react_session__')['url']  + "/api/v1/panel/user", requestOptions)
      .then(res => res.json())
      .then(
        (result) => {

          if (result.data == undefined) {
            window.location.replace("/login");
          }

          this.setState({
            isLoaded: true,
            data: result.data,
            formInput: ''
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            work: true,
            error
          });
        }
      )
  }

  render() {

    const { data } = this.state;

    return (


      <div>


        <div className="topnavmobile">

          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">پنل کاربری برییج</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/panel/dashboard">داشبورد</Nav.Link>
                <Nav.Link as={Link} to="/panel/coins">قیمت ها</Nav.Link>
                <Nav.Link as={Link} to="/panel/alertlist">هشدار قیمت</Nav.Link>
                <Nav.Link as={Link} to="/panel/trade">خرید و فروش</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

        </div>

        <div className="topnav">

          <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" style={{
            right: '240px',
            paddingTop: '2rem',
            backgroundColor: 'white',
            marginLeft: '1rem',
          }}>
            <Navbar.Brand href="#home"><i className="fa fa-home"></i>داشبورد</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">

              </Nav>
              <Nav>
                <Nav.Link href="#deets">{this.state.data.name}</Nav.Link>
                <Nav.Link as={Link} to="/logout">
                  خروج
          </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

        </div>



        <div className="sidebar">
          <Link as={Link} to="/panel/dashboard" >داشبورد</Link>
          <Link as={Link} to="/panel/coins" >قیمت ها</Link>
          <Link as={Link} to="/panel/alertlist" >هشدار قیمت</Link>
          <Link as={Link} to="/panel/trade" >خرید و فروش</Link>
        </div>

      </div>

    );
  }
}




export default HeaderPanel;
